import type { CartQueryDataReturn } from "@shopify/hydrogen"
import type { CartWarning } from "@shopify/hydrogen/storefront-api-types"

import * as React from "react"

import { isEqual } from "@iyk/lodash"
import { useFetchers } from "@remix-run/react"
import { isCartFetcherKey } from "./cart-fetchers.ts"

// #region Hook

export function useCartErrors() {
  const fetchers = useFetchers()
  const [cartErrors, setCartErrors] = React.useState<CartError[]>([])

  React.useEffect(() => {
    const cartFetchers = fetchers.filter((f) => isCartFetcherKey(f.key) && f.state === "idle")

    const newErrors = cartFetchers.reduce<CartError[]>((acc, f) => {
      const data = f.data as CartQueryDataReturn | undefined
      if (!data) return acc
      return acc.concat(extractCartErrors(data))
    }, [])

    setCartErrors((prevCartErrors) =>
      isEqual(newErrors, prevCartErrors) ? prevCartErrors : newErrors,
    )
  }, [fetchers])

  return { cartErrors }
}

// #endregion

// #region Cart warnings

const CART_WARNING_CODE_TO_TITLE: Record<CartWarning["code"], string> = {
  MERCHANDISE_NOT_ENOUGH_STOCK: "Not enough stock",
  MERCHANDISE_OUT_OF_STOCK: "Out of stock",
  PAYMENTS_GIFT_CARDS_UNAVAILABLE: "Gift card unavailable",
  DUPLICATE_DELIVERY_ADDRESS: "Duplicate delivery address",
}

function extractCartErrors(cartResponse: CartQueryDataReturn): CartError[] {
  const { errors, userErrors, warnings } = cartResponse

  return [
    ...(errors?.map((error) => ({
      title: "Cart error",
      message: error.message,
    })) ?? []),

    ...(userErrors?.map((error) => ({
      title: "Cart error",
      message: error.message,
    })) ?? []),

    ...(warnings?.map((warning) => ({
      title: CART_WARNING_CODE_TO_TITLE[warning.code],
      message: warning.message,
    })) ?? []),
  ]
}

// #endregion

// #region Helpers

function hasItems(items?: Array<unknown>) {
  return items ? items.length > 0 : false
}

export function hasCartResponseErrors(cartResponse: CartQueryDataReturn) {
  const { errors, userErrors, warnings } = cartResponse
  return hasItems(errors) || hasItems(userErrors) || hasItems(warnings)
}

// #endregion

// #region Types

type CartError = { title: string; message: string }

// #endregion
